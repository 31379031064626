import { Controller } from "stimulus";
import { submitForm } from "init/ajax";
import { openModal } from "../modal/utils";



export default class extends Controller {  

  static targets = ["modal", "form", "submit", "token", "modalName"]

  connect() {
    window.addEventListener('open-resort-modal', e => this.openModal(e), {});
  }

  openModal = e => {    
    const element = this.modalNameTarget
    const name = element.dataset.value
    e.preventDefault();
    if (e.detail.modalName === name) {
      openModal(this.modalTarget);
    }
  };
  
  async submit(e) {
    e.preventDefault();
    e.returnValue = false;
    
    const token = await window.grecaptcha.enterprise.execute('6LcT6F4iAAAAAK_SIlVjZSBjS88mT6bQ70jiOYS5', {action: 'CONTACT'});
    this.tokenTarget.value = token;
    

      submitForm(this.formTarget).then(response => {
        if (response.success === "true") {
          this.modalTarget.classList.add("success");
          this.formTarget.reset();
        } else {
          this.formTarget.innerHTML = `
            <strong>Sorry, there was an error with your request</strong>
          `;
        }
      });
  }

}
