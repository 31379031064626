import { Controller } from "stimulus";

import { openModal } from "../modal/utils";

export default class extends Controller {
  static targets = ["modal"];

  openModal = e => {
    e.preventDefault();
    e.stopPropagation();
    openModal(this.modalTarget);
  };
}
