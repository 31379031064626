import { Controller } from "stimulus";
import Glide from '@glidejs/glide'

export default class extends Controller {
  static targets = ["slider", "button"]

  connect() {
    this.glide = new Glide(this.sliderTarget, { startAt: 0, perView: 1, gap: 20, direction: 'ltr' }).mount();
    this.glide.on('move', () => {
      const activeIndex = this.glide.index;
      this.updateFloorplanVisibility(activeIndex);
    });

    this.glide.mount();

    const activeIndex = this.glide.index;
    this.updateFloorplanVisibility(activeIndex);

    const buttonMicrositeLink = document.querySelectorAll('button.button-microsite-link');
    buttonMicrositeLink.forEach((button) => {
      button.addEventListener('click', () => {
        if (button.dataset.target === '_blank') {
          window.open(button.dataset.url, '_blank');
        } else {
          window.open(button.dataset.url, '_self');
        }
      });
    })
  }

  updateFloorplanVisibility(activeIndex) {
    const floorplanBlocks = document.querySelectorAll('.events_floorplan_block');
    floorplanBlocks.forEach((block, index) => {
      const isCurrentSlide = index === activeIndex;
      if (isCurrentSlide) {
        block.style.display = "block";
      } else {
        block.style.display = "none";
      }
    });
  }

  openModal = e => {
    const element = this.buttonTarget
    const name = element.dataset.value

    if (e.target.dataset.modalId !== undefined && e.target.dataset.modalId !== '') {
      e.preventDefault();
      const event = new CustomEvent('modal:open', {
        detail: {
          modal_id: element.dataset.modalId
        }
      });
      document.dispatchEvent(event);
    } else {
      const event = new CustomEvent(`open-resort-modal`, {
        detail: {
          modalName: name,
        }
      });
      window.dispatchEvent(event);
    }
  };


  disconnect() {
    this.glide.destroy()
  }
}
