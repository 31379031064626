import Flatpickr from "stimulus-flatpickr";
import { setCookie, deleteCookie, getCookie } from "init/cookie";

const moreThanOneDateError = "You must select at least more than one date";
let noBreakDownABTest = false;

export default class extends Flatpickr {
  static targets = [
    "availability",
    "availabilityDefaults",
    "rightArrow",
    "leftArrow"
  ];

  initialize() {
    this.form = this.element.closest('.contact-form-group-travel');
    this.availabilityData = JSON.parse(this.availabilityTarget.innerHTML);
    this.availabilityDefaults = JSON.parse(
      this.availabilityDefaultsTarget.innerHTML
    );
    if (this.availabilityData === undefined) return;
    this.config = {
      inline: true,
      mode: "range",
      minDate: "today",
      clickOpens: false,
      nextArrow: document.querySelector(".month-arrow.next-month").innerHTML,
      prevArrow: document.querySelector(".month-arrow.previous-month")
        .innerHTML,
      showMonths: 2
    };

    // AB test
    noBreakDownABTest = getCookie('ABTest_nobreakdown').length > 0;
  }

  connect() {
    if (this.availabilityData === undefined) return;
    this.datesApplied = false;
    this.calendarOverlay = document.querySelector('.calendar-overlay');
    // TODO: this is fucked up, this calendar is depending of a external block.
    this.originalTravelDatesPlaceholder = document.querySelector(".travel-dates-label").textContent;
    this.arriveTarget = this.form.querySelector("#arrive");
    this.departTarget = this.form.querySelector("#depart");
    this.promoCodeTarget = document.getElementById("coupon");
    this.unitIdTarget = document.getElementById("unit_id");
    this.datepickers = document.querySelectorAll(
      '[data-controller="calendar-hero-light"]'
    );
    /* Fire super.connect() before using `this.fp` so it's available */
    super.connect();
    /* Month Arrow Controls */
    this.rightArrowTarget.addEventListener("click", () =>
      this.fp.changeMonth(1)
    );
    this.leftArrowTarget.addEventListener("click", () =>
      this.fp.changeMonth(-1)
    );

    this.initClearDatesButton();
    this.initPLPactions();
  }

  initClearDatesButton() {
    const calendarEl = this.element.parentNode;
    const clearButton = calendarEl.querySelector(".clear-dates button");
    clearButton.addEventListener("click", () => {
      this.clearDates();
    });
  }

  initPLPactions() {
    // if (!document.querySelector(".plp-bar")) return;
    const dateSelector = this.form.querySelector(".select-dates");
    dateSelector.addEventListener("click", this.submitPLP.bind(this));
  }

  submitPLP(e) {
    e.preventDefault();
    /* eslint-disable no-undef */
    // const queryData = Site.serializedQueryParams();
    const arrive = this.arriveTarget.value;
    const depart = this.departTarget.value;

    if (arrive === "" || depart === "") return;

    if (arrive === depart) {
      this.setErrorMessages(moreThanOneDateError);
    }
    document.querySelectorAll(".travel-dates-label").forEach(el => el.textContent = `${arrive} - ${depart}`);
    document.body.style.overflowY = "auto";
    this.form.querySelector(".calendar-overlay").classList.remove("open");
  }

  initDates() {
    if (this.arriveTarget.value && this.departTarget.value) {
      /* eslint-disable no-underscore-dangle */
      const fp = this.element._flatpickr;
      const arriveDate = fp.parseDate(this.arriveTarget.value, "Y-m-d");
      const departDate = fp.parseDate(this.departTarget.value, "Y-m-d");
      const selectedDates = [arriveDate, departDate];
      this.datesApplied = true;
      fp.setDate(selectedDates, true);
      if (!this.booking_breakdown) return;
      this.submit();
    }
  }

  totalsAlreadySet() {
    return this.item_container.innerHTML.replace(/^\s+/, "") !== "";
  }

  // eslint-disable-next-line no-unused-vars
  change(selectedDates, dateStr, instance) {
    this.calendarOverlay.querySelector(".selected-nights").textContent = "";
    this.calendarOverlay.querySelector(".selected-nights").classList.remove('selected')

    if (selectedDates[0] === undefined) return

    if (selectedDates.length === 2) {
      const startDate = instance.formatDate(selectedDates[0], 'M j, Y');
      const endDate = instance.formatDate(selectedDates[1], 'M j, Y');

      this.arriveTarget.value = startDate;
      this.departTarget.value = endDate;

      const timeDiff = Math.abs(selectedDates[1].getTime() - selectedDates[0].getTime());
      const numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));

      if (numberOfNights > 0) {
        const selectedNights = this.calendarOverlay.querySelector(".selected-nights")
        selectedNights.textContent = `${numberOfNights} nights`
        selectedNights.classList.add("selected")
      }
    }

    if (selectedDates.length > 1) {
      const startDate = instance.formatDate(selectedDates[0], 'M j, Y');
      const endDate = instance.formatDate(selectedDates[1], 'M j, Y');

      if (startDate !== endDate) {
        this.activateSelectDatesBtn();
      }
    } else {
      this.deactivateSelectDatesBtn();
    }
  }

  getDaysArray(start, end) {
    for (var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)) {
      arr.push(new Date(dt))
    }
    return arr;
  }

  clearInstanceDates(instance) {
    const firstVisibleDay = document.querySelector('.flatpickr-day:not(.hidden)')
    const date = new Date(firstVisibleDay.getAttribute('aria-label'))
    instance.clear()
    instance.jumpToDate(date)
  }

  dayCreate(dObj, dStr, fp, dayElem) {
    if(Object.keys(this.availabilityData).length == 0){
      return 0;
    }
    let today = new Date();
    today = fp.formatDate(today, "Y-m-d");
    const humanDate = dayElem.getAttribute("aria-label");
    const formatted = fp.formatDate(new Date(humanDate), "Y-m-d");
    const day = this.availabilityData[formatted];
    const text = "";

    const minDisabled = this.getMinBookingWindowDates();

    /* Typecast `minDisabled` to a String for ie11 which lacks Object.includes() */
    if (`${minDisabled}`.includes(formatted)) {
      dayElem.classList.add("flatpickr-disabled");
    }
  }

  getMinBookingWindowDates() {
    const minWindow = this.availabilityDefaults.minBookingWindow;
    const range = [...Array(minWindow).keys()];
    return range.map(num => this.getFormattedDate(num));
  }

  getFormattedDate(num) {
    const today = new Date();
    const date = new Date(today);
    if (num > 0) {
      date.setDate(date.getDate() + num);
    }
    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const yyyy = date.getFullYear();

    return `${yyyy}-${mm}-${dd}`;
  }

  getLowestMaximum() {
    let availability = this.availabilityData;
    const queryData = Site.serializedQueryParams();
    if (queryData.checkin && queryData.checkout) {
      const range = this.availabilityRange()
      availability = range.reduce((carry, date) => {
        carry[date] = availability[date]
        return carry
      }, {});
    }

    const rates = Object.keys(availability).map(key => {
      const { min, max } = availability[key].rate;
      // sometimes Track mixes up max and min, so we select lowest
      const rate = Math.min(min, max);
      return rate;
    });
    return Math.min(...rates);
  }

  setTotals() {
    const lowest = Math.round(this.getLowestMaximum());
    const actualTotals = document.querySelectorAll(".actual-total");
    actualTotals.forEach(actualTotal => {
      // eslint-disable-next-line no-param-reassign
      actualTotal.innerText = `$${lowest}`;
    });
  }

  clearDates() {
    this.fp.setDate([]);
    this.deactivateSelectDatesBtn();
    this.arriveTarget.value = "";
    this.departTarget.value = "";
    document.querySelectorAll(".travel-dates-label").textContent = this.originalTravelDatesPlaceholder;
    this.calendarOverlay.querySelector(".selected-nights").classList.remove('selected')
  }

  clearErrorMessages() {
    const errorContainers = document.querySelectorAll(".select-dates .error");
    errorContainers.forEach(errorContainer => {
      // eslint-disable-next-line no-param-reassign
      errorContainer.innerText = "";
    });
  }

  setErrorMessages(message) {
    const event = new Event('calendar-error-triggered', {});
    document.dispatchEvent(event);

    const errorContainers = document.querySelectorAll(".select-dates .error");
    errorContainers.forEach(errorContainer => {
      // eslint-disable-next-line no-param-reassign
      errorContainer.innerText = message;
    });
  }

  availabilityRange() {
    const dateArray = [];
    const startDate = new Date(this.arriveTarget.value.replace(/-/g, "/"));
    const endDate = new Date(this.departTarget.value.replace(/-/g, "/"));
    let currDate = startDate;
    while (currDate <= endDate) {
      const newDate = this.fp.formatDate(new Date(currDate), "Y-m-d");
      dateArray.push(newDate);
      currDate = currDate.addDays(1);
    }
    return dateArray;
  }

  updateQuote(response) {
    this.clearErrorMessages();

    if (response.status === 422 && response.ruleCodeId === 200) {
      // this means that the dates selected are in the past so clear dates
      this.clearDates();
      return;
    }
    if (response.status === 422 || response.status === 500) {
      // this means that the dates selected do not meet the minimum requirement
      // response.ruleCodeId == 402
      // Track made a generic update which renders this useless error response
      // "Available rate type was not found for the quote parameters."
      // so we have to do the following logic to find the minimum night stay in
      // the given time range.
      const range = this.availabilityRange();
      const avail = this.availabilityData;
      const minStay = Math.max(...range.map(day => avail[day].stay.min));

      if (response.detail.match(/(Promo)/i)) {
        this.setErrorMessages(
          `Sorry, coupon code is not valid for this listing. Please try another coupon code.`
        );
      } else {
        this.setErrorMessages(
          `Your date selection requires a ${minStay} night stay minimum. Please extend your stay.`
        );
      }
      return;
    }

    // since the response did't return sooner, assume success
    /* eslint-disable-next-line no-undef */
    Site.quoteStored = true;

    // Write these dates to the PLP filter, in case we go back:
    const plpQuery = {
      checkin: this.arriveTarget.value,
      checkout: this.departTarget.value
    };
    setCookie("filter_by_dates", JSON.stringify(plpQuery), 3);

    // close overlay if open
    document.querySelector(".calendar-overlay").classList.remove("open");

    const items = response.parsed_items;
    if (items === undefined) return;
    /* Wipe any existing items */
    this.item_container.innerHTML = "";
    /* Populate the list */
    // ABTEST - ORIGINAL
    if (!noBreakDownABTest) {
      items.forEach(item => {
        if (!item.label || item.label === "avg_rate_item") {
          const bookingItem = document.createElement("DIV");
          bookingItem.classList.add("booking-item");
          const desc = document.createElement("DIV");
          desc.classList.add("item-desc");
          desc.innerText = item.name;
          const total = document.createElement("DIV");
          total.classList.add("item-total");
          total.innerText = `${item.value}`;
          bookingItem.appendChild(desc);
          bookingItem.appendChild(total);
          this.item_container.appendChild(bookingItem);
        }
      });
    }
    // ABTEST - Variant
    let ABTotal = 0;
    if(noBreakDownABTest){
      let counter = 0;
      items.forEach( item => {
        if ((!item.label || item.label === "avg_rate_item")) {
          if(counter < 1) {
            const bookingItem = document.createElement("DIV");
            bookingItem.classList.add("booking-item");
            const desc = document.createElement("DIV");
            desc.classList.add("item-desc");
            desc.innerText = item.name;
            const total = document.createElement("DIV");
            total.classList.add("item-total");
            total.innerText = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.value.replace('$', ''));
            bookingItem.appendChild(desc);
            bookingItem.appendChild(total);
            this.item_container.appendChild(bookingItem);

            ABTotal = item.value;
          }
          counter++;
        }
      });
    }

    if (!this.datesApplied) {
      this.pdp_bar.classList.add("open");
    }
    this.datesApplied = false;
    const subtotal = document.querySelector(".booking-subtotal .item-total");
    const barTotal = document.querySelector(".actual-total");
    const fromText = document.querySelector(".from");
    const totalText = document.querySelector(".title span.bottom");
    subtotal.innerText = `$${response.breakdown.total}`;
    barTotal.innerText = `$${response.breakdown.total}`;
    fromText.style.display = "none";
    totalText.innerText = "Total";
    // replace mobile totals
    this.mobile_pdp_bar.querySelector(
      ".totals"
    ).innerHTML = this.pdp_bar.querySelector(".totals").innerHTML;
    this.mobile_pdp_bar.querySelector(".title").addEventListener("click", e => {
      e.stopPropagation();
      this.mobile_pdp_bar.classList.toggle("open");
    });
    this.mobile_pdp_bar.querySelector(
      ".detailed-totals-bar"
    ).innerHTML = this.booking_breakdown.innerHTML;
    this.mobile_pdp_bar.classList.add("adjusted");
    // 💩 Update the button text, should just call mpb.updateButton() but Stimulus 😢
    this.mobile_pdp_bar.querySelector(
      ".book-now .btn"
    ).textContent = `Book Now`;

    // Variant
    if(noBreakDownABTest) {
      document.querySelectorAll('.booking-subtotal').forEach( el => el.style.display = 'none' );
      document.querySelectorAll('.booking-items .booking-item').forEach ( el => el.style.padding = 0)
      document.querySelectorAll('.totals .bottom').forEach ( el => el.innerText = 'Rent')
      document.querySelectorAll('.actual-total').forEach ( el =>
        el.innerText = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ABTotal.replace('$', ''))
      )
    }
  }

  activateSelectDatesBtn() {
    const dateBtns = document.querySelectorAll(".select-dates button");
    dateBtns.forEach(btn => {
      /* eslint-disable-next-line no-param-reassign */
      btn.disabled = false;
    });
    if (this.item_container) this.item_container.innerHTML = "";
  }

  deactivateSelectDatesBtn() {
    const dateBtns = document.querySelectorAll(".select-dates button");
    dateBtns.forEach(btn => {
      /* eslint-disable-next-line no-param-reassign */
      btn.disabled = true;
    });
  }

  activateBookNow() {
    const bookNow = document.querySelector("#book-now");
    const isInquiry = document.querySelector(".inquiry-overlay");
    const text = isInquiry ? "Inquire" : "Book Now";
    bookNow.innerText = text;
    bookNow.disabled = false;
    if (isInquiry) {
      const arrive = document.querySelector("#arrive").value;
      const depart = document.querySelector("#depart").value;
      document.querySelector("#form_fields_checkin").value = arrive;
      document.querySelector("#form_fields_checkout").value = depart;
    }
  }

  pdpUrl() {
    return `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  }

  submit() {
    const data = {
      unitId: this.unitIdTarget.value,
      arrivalDate: this.arriveTarget.value,
      departureDate: this.departTarget.value,
      clearAddOns: true
    };

    if (this.promoCodeTarget && this.promoCodeTarget.value) {
      data.promoCode = this.promoCodeTarget.value;
    }

    const prevUrl = window.location.href;
    let query = {
      checkin: this.arriveTarget.value,
      checkout: this.departTarget.value
    };

    if (this.promoCodeTarget && this.promoCodeTarget.value) {
      query.coupon = this.promoCodeTarget.value;
    }

    query = Site.objectToQuery(query);
    const url = `${this.pdpUrl()}?${query}`;

    if (this.arriveTarget.value === this.departTarget.value) {
      this.setErrorMessages(moreThanOneDateError);
      return;
    }

    // stop from repeat requests to Track API
    if (Site.quoteStored && prevUrl === url) {
      // close overlay if open
      document.querySelector(".calendar-overlay").classList.remove("open");
    } else {
      /* eslint-disable-next-line no-restricted-globals */
      if (history.pushState) {
        window.history.pushState({ path: url }, "", url);
      } else {
        window.location.href = url;
      }
      this.activateBookNow();
      Site.postJson("/quote", data, this.updateQuote.bind(this));
    }
    document.body.style.overflowY = "auto";
  }
}
