import "./gallery_hero_carousel_section_full_mobile.css";
import "./gallery_hero_carousel_section_full_desktop.css";

// Required Core Stylesheet
import "@glidejs/glide/src/assets/sass/glide.core";

// Optional Theme Stylesheet
//import "@glidejs/glide/src/assets/sass/glide.theme";

import application from "stimulus_application";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const context = require.context("./", true, /_controller\.js$/);
application.load(definitionsFromContext(context));
