import { Controller } from "stimulus";
import MicroModal from 'micromodal';

export default class extends Controller {
  static targets = ["container"];

  connect() {
    MicroModal.init({
      disableScroll: true,
    });
  }

  showLoader() {
    this.containerTarget.classList.add("loading");
  }
}
