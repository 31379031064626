import {Controller} from "stimulus";
import Glide from "@glidejs/glide";
import { submitForm } from "init/ajax";
import { openModal, closeModal } from "../modal/utils";

export default class extends Controller {
  connect() {

  }

  static targets = ["modal", "form", "submit", "token", "link", "modalBody", "form", "callFormButton", "formBlock", "formSuccess"]

  openModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const targetModalId = e.target.dataset.eventsFloorplanId
    const modalEl = this.modalBodyTargets.find(item => item.dataset.eventsFloorplanModalId === targetModalId)
    openModal(modalEl);
    const slider = modalEl.querySelector('.slider-group-events');
    if (slider) {
        const glideSlides = modalEl.querySelectorAll('.glide__slide');
        if (glideSlides.length > 0) {
            this.glide = new Glide(slider, { type: 'carousel', perView: 1, gap: 0, focusAt: 'center' }).mount();
        }
    }

  };

  closeModal = (e) => {
    closeModal(e.target);
    if (this.glide) {
      this.glide.destroy();
    }
  }

  showForm = (event) => {
    const eventTarget = event.target.closest('.block-call-form');
    const eventTargetNextSibling = eventTarget.nextElementSibling;

    eventTarget.querySelector('.modal-cta').classList.add("container-form");
    eventTargetNextSibling.classList.add("container-form-open");

    if (this.formSuccessTarget.classList.contains("open-success-message")) {
      this.formSuccessTarget.classList.remove('open-success-message')
    }
  }

  hideForm = (event) => {
    const eventTarget = event.target.closest('.container-form-open');
    const eventTargetPreviousSibling = eventTarget.previousElementSibling.querySelector('.modal-cta');

    eventTarget.classList.remove("container-form-open");
    eventTargetPreviousSibling.classList.remove("container-form");
  }

  async submit(e) {
    e.preventDefault();
    e.returnValue = false;

    this.formTarget.classList.remove("container-form-open");
    this.callFormButtonTarget.classList.add("container-form-open");

    const token = await window.grecaptcha.enterprise.execute('6LcT6F4iAAAAAK_SIlVjZSBjS88mT6bQ70jiOYS5', {action: 'CONTACT'});
    this.tokenTarget.value = token;


    const floorplanTable = document.querySelector('.events_floorplan_block');
    if (floorplanTable) {
      const target = e.target;
      if (target) {
        this.formBlockTarget.querySelector('input[name="form[fields][first_name]"]').value = target.querySelector('input[name="form[fields][first_name]"]').value
        this.formBlockTarget.querySelector('input[name="form[fields][email]"]').value = target.querySelector('input[name="form[fields][email]"]').value
        this.formBlockTarget.querySelector('input[name="form[fields][phone_number]"]').value = target.querySelector('input[name="form[fields][phone_number]"]').value
        target.closest('.container-form').classList.remove("container-form-open");
        target.closest('.container-form').previousElementSibling.querySelector('.modal-cta.container-form').classList.add("container-form-open");
      }
    }


    submitForm(this.formBlockTarget).then(response => {
      if (response.success === "true") {
        this.formSuccessTarget.classList.add('open-success-message')
        this.formBlockTarget.reset()
        if (floorplanTable && e.target) {
          e.target.closest('.container-form').previousElementSibling.querySelector('.form-success').classList.add('open-success-message');
          e.target.reset()
        }
      } else {
        this.formBlockTarget.innerHTML = `
          <strong>Sorry, there was an error with your request</strong>
        `;
      }
    });
  }
}
