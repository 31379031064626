import {Controller} from "stimulus";

export default class extends Controller {

    static targets = ["button"];

    showForm = e => {
        const element = this.buttonTarget
        if (e.target.dataset.modalId !== undefined && e.target.dataset.modalId !== '') {
            e.preventDefault();
            const event = new CustomEvent('modal:open', {
                detail: {
                    modal_id: element.dataset.modalId
                }
            });
            document.dispatchEvent(event);
        }
    };
}
