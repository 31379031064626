import { Controller } from "stimulus";



export default class extends Controller {

  static targets = ["button"];

  openModal = e => {
    const element = this.buttonTarget
    const name = element.dataset.value
    const event = new CustomEvent(`open-resort-modal`, {detail: {
      modalName: name,
    }});
    window.dispatchEvent(event);
  };

}
