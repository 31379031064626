import { Controller } from "stimulus";
import { deleteCookie } from "init/cookie";

export default class extends Controller {
  resetDates() {
    const urlObject = new URL(window.location.href);
    if (urlObject.searchParams.get('checkin')) {
      urlObject.searchParams.delete('checkin');
    }
    if (urlObject.searchParams.get('checkout')) {
      urlObject.searchParams.delete('checkout');
    }

    deleteCookie('filter_by_dates')

    window.location.href = urlObject.toString();
  }
}
