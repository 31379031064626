import { Controller } from "stimulus";



export default class extends Controller {

  static targets = ["button"];

  openModal = e => {
    const element = this.buttonTarget
    const name = element.dataset.value

    if (e.target.dataset.modalId !== undefined && e.target.dataset.modalId !== '') {
      e.preventDefault();
      const event = new CustomEvent('modal:open', {
        detail: {
          modal_id: element.dataset.modalId
        }
      });
      document.dispatchEvent(event);
    } else {
      const event = new CustomEvent(`open-resort-modal`, {
        detail: {
          modalName: name,
        }
      });
      window.dispatchEvent(event);
    }
  };

}
