import { Controller } from "stimulus";
import { useThrottle, useDispatch, useDebounce } from 'stimulus-use';

export default class extends Controller {
  static targets = ["searchbarContainer", "searchbarPlaceholder"];

  static throttles = ['handleScroll'];

  static debounces = ['handleScrollEnd'];

  connect = () => {
    useThrottle(this, { wait: 100 });
    useDebounce(this, { wait: 100 });
    useDispatch(this);

    // Set background video on window resize.
    window.addEventListener("resize", this.updateBackgroundVideo);
    // Set background video on initial load.
    this.updateBackgroundVideo();

    if (this.hasSearchbarContainerTarget) {
      this.searchbarDataInit();
    }
  }

  // Set <video>'s src attribute for background video.
  updateBackgroundVideo = () => {
    // DOM Element.
    const bgEl = this.element.querySelector(".bg-video");

    // Do nothing if there's no video.
    if (!bgEl) return;

    // Sources passed from WP into the data attributes.
    const desktopSrc = bgEl.dataset.desktopSrc;
    const mobileSrc = bgEl.dataset.mobileSrc || desktopSrc;

    // Set the src attribute to whichever data attribute is appropriate.
    bgEl.src = window.innerWidth < 700 ? mobileSrc : desktopSrc;
  }

  searchbarDataInit() {
    this.scrollDelta = 60;
    this.prevScrollPositionForSearchbar = 0;
    this.isSearchbarMiniMode = false;
    this.prevScrollPositionForSubHeader = 0;
    this.absoluteSearchbarPosition = this.getAbsoluteSearchbarPosition();
    this.setSearchbarPlaceholderDimensions();
  }

  handleScroll() {
    this.adjustSearchbarAppearance();
    this.adjustHeadersPosition();
    this.adjustSubHeaderAndSearchbarOnScrollUp();
  }

  handleScrollEnd() {
    this.adjustSearchbarAppearance();
  }

  adjustSearchbarAppearance() {
    if (window.scrollY > this.absoluteSearchbarPosition) {
      this.fixSearchbar();
      this.handleSearchbarMiniMode();
    } else {
      this.unfixSearchbar();
      this.handleSearchbarNormalMode();
    }
  }

  adjustHeadersPosition() {
    if (window.scrollY > this.absoluteSearchbarPosition) {
      this.dispatchHeaderEvents("unfixHeader");
      this.dispatchHeaderEvents("unfixSubHeader");
    } else {
      this.dispatchHeaderEvents("fixHeader");
      this.dispatchHeaderEvents("fixSubHeader");
    }
  }

  adjustSubHeaderAndSearchbarOnScrollUp() {
    if (window.scrollY < this.prevScrollPositionForSubHeader) {
      this.dispatchHeaderEvents("fixSubHeader");
      this.dispatchHeaderEvents("fixHeader");
      this.searchbarContainerTarget.classList.add('under-header');
    } else {
      this.searchbarContainerTarget.classList.remove('under-header');
    }
    this.prevScrollPositionForSubHeader = window.scrollY;
  }

  getAbsoluteSearchbarPosition() {
    let absoluteY = 0;
    let currentElement = this.searchbarContainerTarget;

    // Traverse the offset parent chain until body element is reached
    while (currentElement.offsetParent !== null) {
      // Add current element's offsetTop to the absoluteY value
      absoluteY += currentElement.offsetTop;
      // Move to the next offset parent
      currentElement = currentElement.offsetParent;
    }

    return absoluteY + this.searchbarContainerTarget.offsetHeight;
  }

  setSearchbarPlaceholderDimensions() {
    const { width, height } = this.searchbarContainerTarget.getBoundingClientRect();

    this.searchbarPlaceholderTarget.style.width = `${width}px`;
    this.searchbarPlaceholderTarget.style.height = `${height}px`;
  }

  fixSearchbar() {
    this.searchbarContainerTarget.classList.add('fixed');
    this.searchbarPlaceholderTarget.classList.remove('hidden');
  }

  unfixSearchbar() {
    this.searchbarContainerTarget.classList.remove('fixed');
    this.searchbarPlaceholderTarget.classList.add('hidden');
  }

  setSearchbarMiniModeFlag() {
    this.isSearchbarMiniMode = true;
  }

  resetSearchbarMiniModeFlag() {
    this.isSearchbarMiniMode = false;
    this.prevScrollPositionForSearchbar = window.scrollY;
  }

  handleSearchbarMiniMode() {
    if (this.isSearchbarMiniMode) return;

    if (Math.abs(window.scrollY - this.prevScrollPositionForSearchbar) > this.scrollDelta) {
      this.dispatch('searchbarMini');
    }
  }

  handleSearchbarNormalMode() {
    if (!this.isSearchbarMiniMode) return;

    this.dispatch('searchbarNormal');
  }

  dispatchHeaderEvents(event) {
    window.dispatchEvent(new CustomEvent(event));
  }
}
