import "components/template_resorts/template_resorts";
import "components/video_with_alternative_image/video_with_alternative_image";

import "components/text_section_overview/text_section_overview";
import "components/text_quote_overview_block/text_quote_overview_block";
import "components/gallery_hero_carousel_section_full/gallery_hero_carousel_section_full";
import "components/who_we_are/who_we_are";
import "components/additional_navbar/additional_navbar";
import "components/viewport_hero_content/viewport_hero_content";
import "components/viewport_without_extranav/viewport_without_extranav";

import "components/gallery_hero_carousel_section_full_trio/gallery_hero_carousel_section_full_trio";
import "components/gallery_hero_carousel_section_full_amenities/gallery_hero_carousel_section_full_amenities";
import "components/gallery_carousel_section/gallery_carousel_section";
import "components/viewport_hero_content_map/viewport_hero_content_map";
import "components/text_section_overview_amenities/text_section_overview_amenities";
import "components/faq_utility_toggle/faq_utility_toggle";
import "components/cta_back_block/cta_back_block";

import "components/calendar_hero_light/calendar_hero_light"
import "components/calendar_hero/calendar_hero"

import "components/side_stick_button/side_stick_button"
import "components/quote/quote";
import "components/text_quote_overview/text_quote_overview";
import "components/contact_modal_resort/contact_modal_resort";
import "components/headline_block/headline_block";
import "components/gallery_hero_carousel/gallery_hero_carousel";
import "components/sliders_centered/sliders_centered";

// components from group and events microsite blocks
import "components/events_floorplan/events_floorplan";
import "components/events_floorplan_cta/events_floorplan_cta";

// custom components for resorts from group events
import "components/group_events_modal/group_events_modal";
import "components/group_events_modal_slider/group_events_modal_slider";
import "components/group_events_modal_more_about_this/group_events_modal_more_about_this";
import "components/group_events_modal_occupancy/group_events_modal_occupancy";
import "components/group_events_modal_space/group_events_modal_space";
import "components/microsite_form/microsite_form";
import "components/events_alt_info/events_alt_info";
import "components/events_alt_info_carousel/events_alt_info_carousel";
import "components/resort_accommodation/resort_accommodation";
import "components/product_card/product_card";
import "components/product_card_list/product_card_list";
import "components/promo_banner/promo_banner";
import "components/filters_bar/filters_bar";
import "components/filters/filters";
import "components/sort_by/sort_by";
import "components/no_results_found/no_results_found";
import "components/contact_form_group_travel/contact_form_group_travel"

import "components/filters_bar_select/filters_bar_select";
import "components/modal_unit_types/modal_unit_types";
import "components/viewport_unit_types/viewport_unit_types";
import "components/viewport_hero/viewport_hero";
import "components/viewport_city/viewport_city";
import "components/viewport_accommodation/viewport_accommodation";

import "components/datepicker_hero_homepage/datepicker_hero_homepage";

import "components/pick_unit_toggle/pick_unit_toggle";
import "components/loading_homes/loading_homes";
import "components/cart_icon/cart_icon"
import "components/viewport_unit_types_with_searchbar/viewport_unit_types_with_searchbar";
import "components/matterport/matterport";
import "components/small_hero/small_hero";
import "components/special_offers/special_offers";
import "components/special_listing/special_listing";
import "components/template_special_offers/template_special_offers";





const resizeDependent = () => {
  let vh = window.innerHeight * 0.01;
  let vw;

  const wpSiteBlocks = document.body;
  if (wpSiteBlocks) {
    vw = wpSiteBlocks.clientWidth * 0.01;
  } else {
    vw = document.documentElement.clientWidth * 0.01;
  }
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  document.documentElement.style.setProperty("--vw", `${vw}px`);
};

function updateStyleVariables() {
  window.addEventListener("resize", function() {
    resizeDependent();
  });

  resizeDependent();
}

updateStyleVariables();
