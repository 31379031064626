import { Controller } from "stimulus";
import { useDispatch } from "stimulus-use";

export default class extends Controller {
  static targets = ["backgroundBox", "pickUnitText", "tooltipText", "tooltipButton"];

  connect() {
    useDispatch(this);
    const urlObject = new URL(window.location.href);
    const isUnitType = this.data.get("is-unit-type");
    this.isUnitsLoading = false;

    if (urlObject.searchParams.get("display") === "unit_type") {
      this.showUnitTypes();
    } else if (urlObject.searchParams.get("display") === "unit") {
      this.showUnits();
    } else {
      if (isUnitType === "true") {
        this.showUnitTypes();
      } else {
        this.showUnits();
      }
    }

    this.getTogglePosition();
    document.addEventListener("click", this.handleClose.bind(this));
    document.addEventListener("keydown", this.handleClose.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this.handleClose.bind(this));
    document.removeEventListener("keydown", this.handleClose.bind(this));
  }

  tooltipHandleClick() {
    const isVisible = this.tooltipTextTarget.style.visibility === "visible";
    if (!isVisible && this.isMoreSpaceAbove()) {
      this.tooltipTextTarget.classList.add('tooltip-above');
    } else {
      this.tooltipTextTarget.classList.remove('tooltip-above');
    }

    this.tooltipTextTarget.style.visibility = isVisible ? "hidden" : "visible";
  }

  showUnits() {
    this.backgroundBoxTarget.classList.add("toggled");
  }

  showUnitTypes() {
    this.backgroundBoxTarget.classList.remove("toggled");
  }

  disableToggle() {
    this.isUnitsLoading = true;
    this.backgroundBoxTarget.classList.add("disabled");
  }

  toggle() {
    if (this.isUnitsLoading) return;
    this.saveScrollPosition();


    const urlObject = new URL(window.location.href);

    if (this.backgroundBoxTarget.classList.contains("toggled")) {
      this.showUnitTypes();
      urlObject.searchParams.set("display", "unit_type");
    } else {
      this.showUnits();
      urlObject.searchParams.set("display", "unit");
    }

    const keep = ['display', 'checkin', 'checkout', 'adults', 'children']
    for (const key of urlObject.searchParams.keys()) {
      if (!keep.includes(key)) {
        urlObject.searchParams.delete(key);
      }
    }

    this.disableToggle();
    this.dispatch("unitsLoading");
    window.location.href = urlObject.toString();
  }

  handleClose(e) {
    const { tooltipButtonTarget, tooltipTextTarget } = this;
    const isVisible = tooltipTextTarget.style.visibility === 'visible';

    if (isVisible && !tooltipButtonTarget.contains(e.target) &&
     (!tooltipTextTarget.contains(e.target) || e.key === "Escape")) {
      this.tooltipHandleClick();
    }
  }

  isMoreSpaceAbove() {
    const rect = this.tooltipButtonTarget.getBoundingClientRect();
    const spaceAbove = rect.top;
    const spaceBelow = window.innerHeight - rect.bottom;

    return spaceAbove > spaceBelow;
  }

  getTogglePosition() {
    const toggle = document.querySelector('.pick-unit-toggle');
    this.togglePosition = toggle.getBoundingClientRect().top + window.scrollY - 50;
  }

  saveScrollPosition() {
    localStorage.setItem("scrollPosition", this.togglePosition);
    localStorage.setItem("shouldScroll", "true");
  }
}
