import { Controller } from "stimulus";
import Glide from '@glidejs/glide'

export default class extends Controller {
  connect() {
    if (this.element.querySelectorAll('.glide__slide').length > 0)
      this.glide = new Glide(this.element, { type: 'carousel', gap: 38, perView: 1.5, focusAt: 'center' }).mount();
  }

  disconnect() {
    if (this.glid)
      this.glide.destroy()
  }
}
