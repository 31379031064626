import { Controller } from "stimulus";
import { useIntersection, useDispatch } from "stimulus-use";

export default class extends Controller {
  static targets = ['currentPageNumber', 'pagination', 'loadingMore', 'lastPageNumber', 'leftArrow', 'rightArrow', 'leftArrowFirst', 'rightArrowLast'];

  connect() {
    const options = {
      element: this.element,
      rootMargin: "500px",
    }
    useIntersection(this, options);
    useDispatch(this);
    this.updateCurrentPageNumber();
  }

  appear(entry) {
    const currentPage = parseInt(this.currentPageNumberTarget.textContent, 10);
    const lastPage = parseInt(this.lastPageNumberTarget.textContent, 10);
    if (currentPage < lastPage ) {
      this.dispatch('load-more-units', { currentPage });
    }
  }

  hideLoadingMore(currentPage) {
    const lastPage = parseInt(this.lastPageNumberTarget.textContent, 10);
    if (currentPage === lastPage) {
      this.loadingMoreTarget.classList.add('hide');
    } else {
      this.loadingMoreTarget.classList.remove('hide');
    }
  }

  updateCurrentPageNumber(e) {
    const urlParams = new URLSearchParams(window.location.search);
    let pageNumber = urlParams.get("page");
    if (!pageNumber) {
      pageNumber = '01';
    } else if (pageNumber < 10) {
      pageNumber = `0${pageNumber}`;
    }

    this.currentPageNumberTarget.textContent = pageNumber;

    // Check if left arrow should be disabled
    if (parseInt(pageNumber, 10) === 1) {
      this.leftArrowTarget.classList.add('disabled-arrow');
      this.leftArrowFirstTarget.classList.add('disabled-arrow');
    } else {
      this.leftArrowTarget.classList.remove('disabled-arrow');
      this.leftArrowFirstTarget.classList.remove('disabled-arrow');

    }
    // Check if right arrow should be disabled
    const currentPage = parseInt(pageNumber, 10);
    const totalPages = parseInt(this.lastPageNumberTarget.textContent, 10);
    this.hideLoadingMore(currentPage)

    if (currentPage === totalPages) {
      this.rightArrowTarget.classList.add('disabled-arrow');
      this.rightArrowLastTarget.classList.add('disabled-arrow');
    } else {
      this.rightArrowTarget.classList.remove('disabled-arrow');
      this.rightArrowLastTarget.classList.remove('disabled-arrow');

    }
  }

  nextPage() {
    const currentPage = parseInt(this.currentPageNumberTarget.textContent, 10);
    const totalPages = parseInt(this.lastPageNumberTarget.textContent, 10);

    if (currentPage < totalPages) {
      this.goToPage(currentPage + 1);
    }
  }

  previousPage() {
    const currentPage = parseInt(this.currentPageNumberTarget.textContent, 10);

    if (currentPage > 1) {
      this.goToPage(currentPage - 1);
    }
  }

  goToFirstPage() {
    this.goToPage(1);
  }

  goToLastPage() {
    const lastPage = parseInt(this.lastPageNumberTarget.textContent, 10);
    this.goToPage(lastPage);
  }

  goToPage(pageNumber) {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("page", pageNumber);
    window.location.search = urlParams.toString();
  }
}
