import Glide from "@glidejs/glide";
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.sliders = document.querySelectorAll(".slider-centered .glide");
    window.addEventListener("load", this.mount.bind(this));
  }

  mount() {
    this.sliders.forEach(slider =>
      slider.querySelectorAll(".glide__slide").length > 1
        ? this.glideMount(slider)
        : false
    );
  }

  glideMount(slider) {
    const glide = new Glide(slider);
    glide.on('mount.before', () => {
      const glideArrows = document.querySelectorAll('.right-arrow')
      glideArrows.forEach(function (glideArrow) {
        glideArrow.addEventListener('click', function () {
          glide.go('>')
        })
      })
    })
    glide.mount()
  }
}
