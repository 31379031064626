import {Controller} from "stimulus";

export default class extends Controller {

    connect() { };


    backToTop(event) {
        event.preventDefault();
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

}
