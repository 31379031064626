import {Controller} from "stimulus";
import Glide from "@glidejs/glide";
import {useDispatch} from 'stimulus-use';
import {getCookie} from "../../init/cookie";
import unitQuote from "util/quote";

export default class extends Controller {
    static targets = [
        "rateName", "ratePrice", "minRate",
        "bookBtn", "availabilityBtn",
        "glide", "bulletsContainer"
    ];

    connect() {
        useDispatch(this);
        this.unitType = this.data.get("unit-type");
        this.unitId = this.data.get("unit-id");
        this.unitTypeId = this.data.get("unit-type-id");
        this.checkoutPath = this.data.get("checkout-path");
        this.villaPath = this.data.get("villa-path");
        this.ratesPresent = false;
        this.checkin = this.data.get("checkin");
        this.checkout = this.data.get("checkout");
        this.minRateBackup = this.data.get("min-rate");
        this.setupGlideJS();
        // this.setDatesVariables();
        this.setGuestsVariables();
        this.updateBookButton();
        this.observeVisibility();
    }

    setupGlideJS() {
        const slides = this.glideTarget.querySelector(".glide__slides");

        if (slides.children.length === 0) {
            return;
        }

        this.glide = new Glide(this.glideTarget, {
            type: "carousel",
            perView: 1,
            gap: 20,
            swipeThreshold: 5,
            dragThreshold: 5,
            animationDuration: 5
        }).mount();

        const bullets = this.glideTarget.querySelectorAll('.glide__bullet');
        const bulletsContainer = this.glideTarget.querySelector('.glide__bullets');

        bullets[4].classList.add('glide__bullet--right');

        this.glide.on("run.after", () => {
            const activeBullet = this.glideTarget.querySelector('.glide__bullet--active');

            if (activeBullet) {
                const containerWidth = bulletsContainer.clientWidth;
                const bulletOffsetLeft = activeBullet.offsetLeft;
                const bulletWidth = activeBullet.clientWidth;

                const scrollPosition = bulletOffsetLeft - (containerWidth / 2) + (bulletWidth / 2);

                bulletsContainer.scrollTo({
                    left: scrollPosition,
                    behavior: 'smooth'
                });

                this.updateDots();
            }
        });
    }

    updateDots() {
        const bullets = this.glideTarget.querySelectorAll('.glide__bullet');
        const activeIndex = Array.from(bullets).findIndex(bullet => bullet.classList.contains('glide__bullet--active'));

        bullets.forEach(bullet => {
            bullet.classList.remove('glide__bullet--left', 'glide__bullet--right');
        });

        if (activeIndex === 1) {
            bullets[activeIndex + 3].classList.add('glide__bullet--right');
            bullets[activeIndex - 1].classList.add('glide__bullet--left');
            return
        }

        if (activeIndex === bullets.length - 2) {
            bullets[bullets.length - 1].classList.add('glide__bullet--right');
            bullets[bullets.length - 5].classList.add('glide__bullet--left');
            return
        }

        if (activeIndex === bullets.length - 1) {
            bullets[bullets.length - 5].classList.add('glide__bullet--left');
            return
        }

        if (activeIndex === 0) {
            bullets[activeIndex + 4].classList.add('glide__bullet--right');
            return
        }
        if (activeIndex - 2 >= 0 && activeIndex !== bullets.length - 1) {
            bullets[activeIndex - 2].classList.add('glide__bullet--left');
        }

        if (activeIndex + 2 < bullets.length) {
            bullets[activeIndex + 2].classList.add('glide__bullet--right');
        }
    }

    setDatesVariables() {
        // get dates from url parameters
        const urlParams = new URLSearchParams(window.location.search);
        this.checkin = urlParams.get("checkin");
        this.checkout = urlParams.get("checkout");

        if (this.checkin && this.checkout) return;

        // get dates from cookies if url parameters doesn't contain dates
        const cookies = JSON.parse(getCookie("filter_by_dates") || "{}");
        this.checkin = cookies.checkin;
        this.checkout = cookies.checkout;
    }

    setGuestsVariables() {
        // get guests from url parameters
        const urlParams = new URLSearchParams(window.location.search);
        this.adults = urlParams.get("adults") || 0;
        this.children = urlParams.get("children") || 0;

        if (this.adults || this.children) return;

        // get guests from cookies if url parameters doesn't contain them
        const cookies = JSON.parse(getCookie("filter_by_guests") || "{}");
        this.adults = Number(cookies.adults) || 0;
        this.children = Number(cookies.children) || 0;
    }

    bookingItemParams() {
        return {
            unit_id: this.unitId,
            type: this.unitType,
            unit_type_id: this.unitTypeId,
            arrival_date: this.checkin,
            departure_date: this.checkout,
            adults: this.adults,
            children: this.children,
            total: this.total
        };
    }

    updateBookButton() {
        if (!this.checkin || !this.checkout) {
            this.bookBtnTarget.textContent = 'Choose Dates';
            return;
        }

        this.bookBtnTarget.removeAttribute("disabled");
        this.bookBtnTarget.classList.remove("disabled");
        if (this.hasAvailabilityBtnTarget) {
            this.availabilityBtnTarget.classList.remove("disabled");
        }
    }

    async handleBookNowBtnClick(ev) {
        if (!this.checkin && !this.checkout) {
            this.handleMissingDates();
            return;
        }

        // if (!this.adults) {
        //   this.handleMissingGuests();
        //   return;
        // }

        if (this.ratesPresent) {
            // this.showPickOwnVillaModal();
        } else {
            if (this.unitType === 'unit' || this.unitType === 'unit_type') {
                this.setCurrentEvent(ev);
            }
            await this.sendQuoteRequest();
        }
    }

    setCurrentEvent(e) {
        this.currentEvent = e;
    }

    resetCurrentEvent() {
        if (this.currentEvent) {
            this.currentEvent = null;
        }
    }

    handleMissingDates() {
        this.dispatch('searchbarFilterClicked', {type: 'dates'});
    }

    handleMissingGuests() {
        this.dispatch('searchbarFilterClicked', {type: 'guests'});
    }

    async sendQuoteRequest() {
        if (!this.checkin || !this.checkout) {
            this.resetCurrentEvent();
            return;
        }

        this.bookBtnTarget.classList.add('disabled');
        this.bookBtnTarget.textContent = "Checking ...";

        const data = {
            unit_id: this.unitId,
            type: this.unitType,
            arrival_date: this.checkin,
            departure_date: this.checkout
        };

        const result = await unitQuote(data)
        await this.handleQuoteResponse(result);
    }

    updateRates(name, rate) {
        this.ratePriceTarget.textContent = `$${parseFloat(rate).toLocaleString(
            "en-US"
        )} / night`;
        this.rateNameTarget.textContent = name;
        this.ratesPresent = true;
    }

    disableBookButton() {
        if (this.bookBtnTarget) {
            this.bookBtnTarget.setAttribute("disabled", true);
            this.bookBtnTarget.classList.add('disabled');
            this.bookBtnTarget.textContent = "Unavailable";
        }
    }

    async handleQuoteResponse(response) {
        if (response.status && response.status === 'error') {
            this.disableBookButton();
        } else {
            await this.handleQuoteSuccessfulResponse(response);
        }
    }

    async handleQuoteSuccessfulResponse(response) {
        const {nightly_rate, total} = response;
        this.minRateTargets.forEach(target => target.innerHTML = `$${parseFloat(nightly_rate).toLocaleString()}`);
        this.bookBtnTarget.classList.remove('disabled');
        this.bookBtnTarget.textContent = "Book Now";

        const rateName = 'Best Available Rate:';

        this.total = total;
        // commented to hide popup
        // if (this.unitType === 'unit_type') {
        //   this.updateRates(rateName, nightlyRate);
        //   this.showPickOwnVillaModal();
        // }
        if (this.unitType === 'unit' || this.unitType === 'unit_type') {
            await this.handleProceedToBooking(this.currentEvent);
        }
    }

    showPickOwnVillaModal() {
        const modalId = `product-card-modal-${this.unitId}`;
        MicroModal.show(modalId);
    }

    async handleProceedToBooking(e) {
        e.preventDefault();
        const isUnitType = this.unitType === 'unit_type';

        const params = this.bookingItemParams();
        // const isItemPresentInCart = cart.item(this.unitType, this.unitId);

        // changed const buttonForm = isUnitType ? e.currentTarget : e.target;
        // to this version bc we hide possibility to go to pick unit page
        const buttonForm = isUnitType ? e.target : e.target;

        if (buttonForm.dataset.suggestion) {
            params.arrival_date = buttonForm.dataset.checkin;
            params.departure_date = buttonForm.dataset.checkout;
        }

        const cleanVillaPath = this.villaPath.replace(/^\/+/, '');
        const fullUrl = `${window.location.origin}/${cleanVillaPath}`;

        // if (isItemPresentInCart) {
        //   cart.update(this.unitType, this.unitId, params);
        // } else {
        //   cart.add(params);
        // }

        // this.resetCurrentEvent();

        if (isUnitType) {
            // buttonForm.submit();
            //window.open(fullUrl, '_blank');
            // const modalId = `product-card-modal-${this.unitId}`;
            // MicroModal.close(modalId);
            window.location.href = fullUrl;

        } else {
            // better crap to prevent browser blocking popup
            const exploreLink = this.element.querySelector(`.explore-${this.unitId}`);
            exploreLink.href = fullUrl;
            //exploreLink.click();
            window.location.href = fullUrl;
        }
    }

    async fetchPrice() {
        this.bookBtnTarget.classList.add('disabled');
        this.bookBtnTarget.textContent = "Checking ...";

        const data = {
            unit_id: this.unitId,
            type: this.unitType,
            arrival_date: this.checkin,
            departure_date: this.checkout
        };

        const result = await unitQuote(data);

        if (result.status && result.status === 'error') {
            this.disableBookButton();
            this.minRateTargets.forEach(target => {
                target.classList.toggle("min-rate-loading");
                target.classList.toggle("min-rate-error");
                target.innerHTML = `from ${this.minRateBackup} / night`
            })
        } else {
            const {nightly_rate} = result;
            this.minRateTargets.forEach(target => {
                target.classList.remove("min-rate-loading");
                target.innerHTML = `from $${parseFloat(nightly_rate).toLocaleString("en-US")} / night`
            });
            this.bookBtnTarget.classList.remove('disabled');
            this.bookBtnTarget.textContent = "Book Now";
        }
    }


    /**
     * Observe the visibility of `this.element` in the viewport
     * and call `this.fetchPrice` when it becomes visible.
     */
    async observeVisibility() {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(async entry => {
                if (entry.isIntersecting) {
                    // Element is visible
                    await this.fetchPrice();
                    // Optionally, disconnect observer if you only need to fetch once
                    observer.disconnect();
                }
            });
        });

        observer.observe(this.element);
    }

    disconnect() {
        this.glide.destroy();
    }
}
