import { Controller } from "stimulus";
import Glide from '@glidejs/glide'

export default class extends Controller {
  connect() {
    this.glide = new Glide(this.element, { type: 'carousel', perView: 1, gap: 20 }).mount();
  }

  disconnect() {
    this.glide.destroy()
  }
}