import { Controller } from "stimulus";
import { useThrottle, useDispatch, useDebounce } from 'stimulus-use';

export default class extends Controller {
  static targets = ["searchbarContainer", "searchbarPlaceholder", "toggle"];

  static throttles = ['handleScroll'];

  static debounces = ['handleScrollEnd'];

  connect() {
    useThrottle(this, { wait: 100 });
    useDebounce(this, { wait: 100 });
    useDispatch(this);

    this.scrollDelta = 60;
    this.prevScrollPositionForSearchbar = 0;
    this.isSearchbarMiniMode = false;
    this.prevScrollPositionForSubHeader = 0;
    this.absoluteSearchbarPosition = this.getAbsoluteSearchbarPosition();
    this.setSearchbarPlaceholderDimensions();
    this.dispatchHeaderEvents("unfixHeader");

  }

  handleScroll() {
    this.adjustSearchbarAppearance();
    if (this.hasToggleTarget) {
      this.adjustTogglePosition();
    }
    this.adjustHeadersPosition();
    this.adjustSubHeaderAndSearchbarOnScrollUp();
    if (this.hasToggleTarget) {
      this.adjustToggleOnScrollUp();
    }
  }

  handleScrollEnd() {
    this.adjustSearchbarAppearance();
  }

  adjustSearchbarAppearance() {
    const isDesktop = window.innerWidth >= 1200;
    if (isDesktop) {
      // Desktop: Fix when top of searchbar reaches bottom of header
      if (window.scrollY + 70 >= this.absoluteSearchbarPosition - this.searchbarContainerTarget.offsetHeight) {
        this.fixSearchbar();
        this.handleSearchbarMiniMode();
      } else {
        this.unfixSearchbar();
        this.handleSearchbarNormalMode();
      }
    } else {
      // Mobile: Fix when bottom of searchbar reaches bottom of header
      if (window.scrollY + 70 >= this.absoluteSearchbarPosition - 300) {
        this.fixSearchbar();
      } else {
        this.unfixSearchbar();
      }
      if (
        window.scrollY + 70 >=
        this.absoluteSearchbarPosition - 100
      ) {
        this.handleSearchbarMiniMode();

      } else {
        this.handleSearchbarNormalMode();
      }
    }
  }

  adjustTogglePosition() {

    if (window.scrollY + 70 >= this.absoluteSearchbarPosition - this.searchbarContainerTarget.offsetHeight) {
      this.fixToggle();
    } else {
      this.unfixToggle();
    }
   
  }

  adjustHeadersPosition() {
    const isDesktop = window.innerWidth >= 1200;
    if (isDesktop) {
      if (window.scrollY + 70 >= this.absoluteSearchbarPosition - this.searchbarContainerTarget.offsetHeight) {
        this.dispatchHeaderEvents("unfixSubHeader");

      } else {
        this.dispatchHeaderEvents("fixSubHeader");
      }
    } else {
      if (window.scrollY + 70 >= this.absoluteSearchbarPosition - 300) {
        this.dispatchHeaderEvents("unfixSubHeader");
      } else {
        this.dispatchHeaderEvents("fixSubHeader");
      }
    }
  }
  
  async loadMoreUnits(e) {
    const { currentPage } = e.detail;
    const url = new URL(window.location);
    url.searchParams.set("page", currentPage + 1);
  
    try {
      const response = await fetch(url, {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
        },
      });
  
      const html = await response.text();
      const doc = new DOMParser().parseFromString(html, "text/html");
      const list = this.element.querySelector("div.product-card-list");
  
      doc.querySelectorAll(".product-card-list > *").forEach(item => {
        list.appendChild(item.cloneNode(true));
      });
  
      window.history.pushState({ page: currentPage + 1 }, "", url.toString());

      this.dispatch('updatePageNumber', { pageNumber: currentPage + 1 })
  
    } catch (error) {
      console.error("Error loading more items:", error);
    }
  }

  adjustSubHeaderAndSearchbarOnScrollUp() {
    if (window.scrollY < this.prevScrollPositionForSubHeader) {
      this.dispatchHeaderEvents("fixSubHeader");
      this.searchbarContainerTarget.classList.add('under-header');
    } else {
      this.searchbarContainerTarget.classList.remove('under-header');
    }
    this.prevScrollPositionForSubHeader = window.scrollY;
  }
  
  adjustToggleOnScrollUp() {
    const searchbarClassList = this.searchbarContainerTarget.classList;
    if (searchbarClassList.contains('fixed') && searchbarClassList.contains('under-header') && searchbarClassList.contains('mini')) {
      this.toggleTarget.classList.add('under-search');
    } else {
      this.toggleTarget.classList.remove('under-search');
    }
  }

  getAbsoluteSearchbarPosition() {
    let absoluteY = 0;
    let currentElement = this.searchbarContainerTarget;

    // Traverse the offset parent chain until body element is reached
    while (currentElement.offsetParent !== null) {
      // Add current element's offsetTop to the absoluteY value
      absoluteY += currentElement.offsetTop;
      // Move to the next offset parent
      currentElement = currentElement.offsetParent;
    }

    return absoluteY + this.searchbarContainerTarget.offsetHeight;
  }

  setSearchbarPlaceholderDimensions() {
    const { width, height } = this.searchbarContainerTarget.getBoundingClientRect();

    this.searchbarPlaceholderTarget.style.width = `${width}px`;
    this.searchbarPlaceholderTarget.style.height = `${height}px`;
  }

  fixSearchbar() {
    this.searchbarContainerTarget.classList.add('fixed');
    this.searchbarPlaceholderTarget.classList.remove('hidden');
  }

  unfixSearchbar() {
    this.searchbarContainerTarget.classList.remove('fixed');
    this.searchbarPlaceholderTarget.classList.add('hidden');
  }

  fixToggle() {
    this.toggleTarget.classList.add('fixed-toggle');
  }

  unfixToggle() {
    this.toggleTarget.classList.remove('fixed-toggle');
  }

  setSearchbarMiniModeFlag() {
    this.isSearchbarMiniMode = true;
  }

  resetSearchbarMiniModeFlag() {
    this.isSearchbarMiniMode = false;
    this.prevScrollPositionForSearchbar = window.scrollY;
    if (this.hasToggleTarget) {
      this.toggleTarget.classList.remove('mini');
    }
  }

  handleSearchbarMiniMode() {
    if (this.isSearchbarMiniMode) return;

    if (Math.abs(window.scrollY - this.prevScrollPositionForSearchbar) > this.scrollDelta) {
      this.dispatch('searchbarMini');
      this.searchbarContainerTarget.classList.add('mini');
      if (this.hasToggleTarget) {
        this.toggleTarget.classList.add('mini');
      }

    }
  }

  handleSearchbarNormalMode() {
    if (!this.isSearchbarMiniMode) return;

    this.dispatch('searchbarNormal');
    this.searchbarContainerTarget.classList.remove('mini');
    if (this.hasToggleTarget) {
      this.toggleTarget.classList.remove('mini');
    }

  }

  dispatchHeaderEvents(event) {
    window.dispatchEvent(new CustomEvent(event));
  }
}
