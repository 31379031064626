import { Controller } from "stimulus";
import { loadModalUrl } from "../modal/utils";

export default class extends Controller {

  open(event) {
    event.preventDefault();

    if (event.target.dataset.modalId !== undefined) {
      const modalOpen = new CustomEvent('modal:open', { detail: { modal_id: event.target.dataset.modalId }});
      document.dispatchEvent(modalOpen);
    } else {
      loadModalUrl(event.target.href);
    }
  }
}
