import {Controller} from "stimulus";
import {getCookie} from "init/cookie";

export default class extends Controller {
    static targets = ["toggle", "form", "bedrooms", "results", "submit", "count"];

    connect() {
        this.preSearch();
        document.addEventListener("filters:reset", () =>
            this.resetFilters.bind(this)
        );
    }

    toggleVisibility(evt) {
        evt.target.closest(".filter").classList.toggle("filter--collapsed");
    }

    increaseBedrooms() {
        this.bedroomsTarget.stepUp();
        this.watchForm();
    }

    decreaseBedrooms() {
        this.bedroomsTarget.stepDown();
        this.watchForm();
    }

    watchForm() {
        if (this.countTimeout) clearTimeout(this.countTimeout);

        this.countTimeout = window.setTimeout(this.preSearch.bind(this), 1200);
    }

    submitFilters() {
        const formData = this.getNormalizedFormData();
        const evt = new CustomEvent(`filters:submit`, {
            detail: {
                formData
            }
        });
        document.dispatchEvent(evt);
        this.toggleTarget.checked = false;
    }

    updateFilters(formData) {
        let totalItems = 0;
        for (let pair of formData.keys()) {totalItems++;}

        this.countTarget.textContent = totalItems;
        this.countTarget.classList.toggle("zero", totalItems === 0);

        const evt = new CustomEvent(`filters:update`, {
            detail: {
                formData
            }
        });
        document.dispatchEvent(evt);
    }

    resetFilters() {
        Object.values(this.formTarget.elements).forEach(element => {
            switch (element.type) {
                case "select-one":
                case "select-multiple":
                    for (let i = 0; i < element.options.length; i++) {
                        element.options[i].selected = false;
                    }
                    break;
                case "checkbox":
                    element.checked = false;
                    element.removeAttribute("checked");
                    break;
                case "search":
                case "number":
                    element.value = "";
                    element.setAttribute("value", "");
                    break;
            }
        });
        this.preSearch();
        this.submitFilters();
    }

    getNormalizedFormData() {
        const formData = new FormData(this.formTarget);

        // remove sort_by if equal popularity
        if (formData.has('sort_by') && formData.get('sort_by') === 'popularity') {
            formData.delete('sort_by')
        }

        // eslint-disable-next-line no-shadow
        const normalizedFormData = new FormData();

        // eslint-disable-next-line no-restricted-syntax
        for (const key of formData.keys()) {
            const value = formData.getAll(key);
            if (
                (value.length > 1 || value[0] !== "") &&
                !normalizedFormData.has(key)
            ) {
                if (key === "sort_by" && value[0] === 'popularity') {
                    continue;
                }
                normalizedFormData.append(key, value.join(","));
            }
        }

        return normalizedFormData;
    }

    preSearch() {
        return; // Remove this to make the filters refresh work again
        this.submitTarget.disabled = true;
        const formData = this.getNormalizedFormData();
        this.updateFilters(formData)
        const querySearch = this._cleanSearchParams();
        querySearch.set('resort', this.formTarget.dataset.resortSlug);
        formData.forEach((v, k) => querySearch.set(k, v.toString()))
        fetch(`/api/search/count.json?${querySearch.toString()}`)
            .then(res => res.json())
            .then(data => {
                // this.resultsTarget.innerHTML = data.units;
                this.submitTarget.disabled = data.units === 0;
                this.reDrawFilters(data.filters)
            });
    }

    reDrawFilters(filters = []) {
        filters.forEach((filter) => {
            if (Array.isArray(filter.data)) {
                const prefix = `${filter.name}_`
                if (filter.data.length > 0) {
                    filter.data.forEach((option) => {
                        const el = this.element.querySelector(`[data-option=${prefix}${option.name}]`);
                        if (el === undefined) {
                            return;
                        }
                        this._redrawElement(el, option)
                    })
                } else {
                    const elements = this.element.querySelectorAll(`[data-option^="${prefix}"]`);
                    elements.forEach((el) => {
                        this._redrawElement(el, {count: 0})
                    })
                }
            } else {
                if (filter.name === 'rates') {
                    const prices = this.element.querySelectorAll('[data-rate]');
                    prices.forEach((price) => {
                        const inputEl = price.querySelector('input');
                        const rates = [price.dataset.rateMin, price.dataset.rateMax];
                        if (this._price_disable(rates, filter.data) && !inputEl.checked) {
                            price.classList.add('zero')
                            inputEl.disabled = true;
                        } else {
                            price.classList.remove('zero')
                            inputEl.disabled = false;
                        }
                    })
                }
            }
        })
    }

    _redrawElement(el, option) {
        if (el === null) return;
        const inputEl = el.querySelector('input');
        const counter = el.querySelector('.counter');

        counter.textContent = `(${option.count})`;
        if (option.count === 0 && !inputEl.checked) {
            el.classList.add('zero');
            inputEl.disabled = true;
        } else {
            el.classList.remove('zero');
            inputEl.disabled = false;
        }
    }

    _cleanSearchParams() {
        const keep = [
            "display",
            "resort",
            "checkin",
            "checkout"
        ];
        const querySearch = new URLSearchParams(window.location.search);
        for (const key of querySearch.keys()) {
            if (!keep.includes(key)) {
                querySearch.delete(key);
            }
        }

        // if miss checkin & checkout fetch from cookie
        if (!querySearch.has('checkin')) {
            const currentDateFilter = JSON.parse(getCookie('filter_by_dates') || '{}')
            if (currentDateFilter && currentDateFilter.checkin) {
                querySearch.set('checkin', currentDateFilter.checkin)
                querySearch.set('checkout', currentDateFilter.checkout)
            }
        }

        return querySearch
    }

    _price_disable(price, agg) {
        return price[0] < agg.min < price[1] && agg.max < price[0]
    }
}
