import { Controller } from "stimulus";
import Glide from '@glidejs/glide'

export default class extends Controller {
  static targets = ["carousel"]

  connect() {
    if (this.element.querySelectorAll('.glide__slide').length > 0)
      this.glide = new Glide(this.carouselTarget, { type: 'carousel', perView: 1.3, gap: 16, focusAt: 'center'}).mount()
  }

  disconnect() {
    if (this.glid)
      this.glide.destroy()
  }
}
