import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["trackResort", "trackDestination"];

  connect() {
    if (this.hasTrackResortTarget || this.hasTrackDestinationTarget ) {
      this.navbar = document.querySelector(".additional_navbar");
      this.menu = document.querySelector(".additional_navbar .menu");
      this.dates = document.querySelector(".additional_navbar .dates");
      this.calcNavbarAdditionStyles(0)();
      window.addEventListener("resize", this.calcNavbarAdditionStyles());

      this.trackResort = this.trackResortTarget.innerText;
      this.trackDestination = this.trackDestinationTarget.innerText;
      this.overlay = document.getElementById("calendar-overlay");
      this.closeBtn = this.overlay.querySelector(".close-calendar");
      this.closeBtn.addEventListener("click", this.close.bind(this));
      this.searchButton = document.querySelector(".additional_navbar .search-btn");
      this.calendarSelectDateButton = document.querySelector(".calendar-hero .submit-button.select-dates");

      this.calendarSelectDateButton.addEventListener('click', this.selectDates, true);

      const checkin = document.querySelector(".check-in-selector .inner-text");
      const checkout = document.querySelector(".check-out-selector .inner-text");
      const linksBetweenIDsAndOutput = { "arrive": checkin, "depart": checkout};
      const linksBetweenIDsAndCookies = { "arrive": "checkin", "depart": "checkout"};
      const cookieName = 'filter_by_dates';
      const observer = new MutationObserver((mutationList) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const mutation of mutationList) {
          if (mutation.type === "attributes" && mutation.attributeName === "value") {
            if (!mutation.target.value) {
              delete linksBetweenIDsAndOutput[mutation.target.id].dataset.value;
            } else {
              linksBetweenIDsAndOutput[mutation.target.id].dataset.value = mutation.target.value;
            }
            const currentDateFilter = JSON.parse(this.getCookie(cookieName)) || {};
            currentDateFilter[linksBetweenIDsAndCookies[mutation.target.id]] = mutation.target.value;
            this.setCookie(cookieName, JSON.stringify(currentDateFilter));
            this.checkSearchPosibility();
          }
        }
      });
      this.searchButton.addEventListener('click', () => {
        this.checkSearchPosibility();
      });
      const config = { attributes: true };
      this.inputs = Object.keys(linksBetweenIDsAndOutput).map((item) => {
        const input = document.createElement("input");
        input.type = "hidden";
        input.id = item;
        input.name = item;

        return input;
      });
      const content = document.getElementById("content");
      const currentDateFilter = JSON.parse(this.getCookie(cookieName));
      this.inputs.forEach((input) => {
        observer.observe(input, config);
        content.appendChild(input);
        if (currentDateFilter)
          input.setAttribute("value", currentDateFilter[linksBetweenIDsAndCookies[input.id]]);
      });
    }
  }

  close(e) {
    document.body.style.overflowY = "auto";
    this.overlay.classList.remove("open");
  }

  open(e) {
    document.body.style.overflow = "hidden";
    this.overlay.classList.add("open");
  }

  toggleDestinationMenu() {
    this.destinationsTarget.classList.toggle("open");
  }

  checkSearchPosibility = () => {
    let checkinInputValue;
    let checkoutInputValue;
    this.inputs.forEach((input) => {
      switch (input.id) {
        case 'arrive':
          checkinInputValue = input.value;
          break;
        case 'depart':
          checkoutInputValue = input.value;
          break;
        default:
      }
    });
    if (checkinInputValue && checkoutInputValue) {
      this.searchButton.href = [
        `${document.location.origin}`,
        `/stay?`,
        `destinations=${this.trackDestination}`,
        `&`,
        `resorts=${this.trackResort}`,
        `&`,
        `checkin=${checkinInputValue}`,
        `&`,
        `checkout=${checkoutInputValue}`,
      ].join('');
    } else {
      this.searchButton.href = [
        `${document.location.origin}`,
        `/stay?`,
        `destinations=${this.trackDestination}`,
        `&`,
        `resorts=${this.trackResort}`,
      ].join('');
    }
  };

  selectDates = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = [
      `${document.location.origin}`,
      `/stay?`,
      `destinations=${this.trackDestination}`,
      `&`,
      `resorts=${this.trackResort}`,
    ].join('');

    this.close(e);
  }

  setCookie = (cname, cvalue, exdays = 1) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${cvalue};${expires};path=/`;
  }

  getCookie = (cname) => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return false;
  }

  executeWithDebounce = (deb, func, ...params) => {
    if (this.timer)
      clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.timer = null;
      func.call(this, ...params)
    }, deb);
  }

  calcNavbarAdditionStyles = (delay = 300) => (e) => {
    this.executeWithDebounce(delay, () => {
      const navbarCompStyles = window.getComputedStyle(this.navbar);
      if (window.innerWidth >= 700 &&
          this.menu.offsetWidth + this.dates.offsetWidth
           <
          this.navbar.offsetWidth - parseFloat(navbarCompStyles.paddingLeft) - parseFloat(navbarCompStyles.paddingRight)
        ) {
        this.menu.style.margin = "auto 0";
        this.dates.style.margin = "auto 0";
      } else {
        this.menu.style.margin = "auto auto 8px";
        this.dates.style.margin = "auto";
      }
    }, e);
  }
}
