import { Controller } from "stimulus";
import Glide from '@glidejs/glide'

export default class extends Controller {
  static targets = ["button"]

  connect() {
    this.glide = new Glide(this.element, {
      type: 'carousel',
      bound: true,
      perView: 3,
      gap: 26,
      breakpoints: {
        700: {
          perView: 1
        },
      }
    }).mount();
  }

  openModal = e => {
    const element = this.buttonTarget
    const name = element.dataset.value

    if (e.target.dataset.modalId !== undefined && e.target.dataset.modalId !== '') {
      e.preventDefault();
      const event = new CustomEvent('modal:open', {
        detail: {
          modal_id: element.dataset.modalId
        }
      });
      document.dispatchEvent(event);
    } else {
      const event = new CustomEvent(`open-resort-modal`, {
        detail: {
          modalName: name,
        }
      });
      window.dispatchEvent(event);
    }
  };


  disconnect() {
    this.glide.destroy();
  }
}
