import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];


  connect() {
    const sectionClassName = 'section';
    const customClassName = 'custom-margin-top';
    const classAmplifier = ':not(.make .stronger)';
    const additionalOffset = 67;
    const reCalcDebounce = 600;
    const additionalOffsetLastImage = 28;



    const section = this.element.querySelector(`.${sectionClassName}`);
    if (section) {
      const asideHeight = this.element.querySelector('aside').offsetHeight;

      const firstImgWithMargin = section.querySelector('.wrapper').getElementsByTagName('img')[1];
      const secontImgWithMargin = section.querySelector('div:not(.wrapper) > img:nth-of-type(2)');

      const blob = new Blob([
        `.${sectionClassName}${classAmplifier} .${customClassName}--first-img { margin-top: ${asideHeight + additionalOffset}px; }
        .${sectionClassName}${classAmplifier} .${customClassName}--second-img { margin-top: ${asideHeight + additionalOffsetLastImage}px; }`
      ], {type: 'text/css'});
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = window.URL.createObjectURL(blob);
      this.appenedLink = document.querySelector('head').appendChild(link);

      firstImgWithMargin.classList.add(`${customClassName}--first-img`);
      secontImgWithMargin.classList.add(`${customClassName}--second-img`);
    }

    window.addEventListener("resize", () => {
      let windowWidth = window.innerWidth;
      if (this.timer)
        clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.timer = null;
        if (window.innerWidth !== windowWidth) {
          windowWidth = window.innerWidth
          this.appenedLink.remove();
          this.connect();
        }
      }, reCalcDebounce);
    }, { once: true });
  }

  openModal = e => {
    const element = this.buttonTarget
    const name = element.dataset.value

    if (e.target.dataset.modalId !== undefined && e.target.dataset.modalId !== '') {
      e.preventDefault();
      const event = new CustomEvent('modal:open', {
        detail: {
          modal_id: element.dataset.modalId
        }
      });
      document.dispatchEvent(event);
    } else {
      const event = new CustomEvent(`open-resort-modal`, {
        detail: {
          modalName: name,
        }
      });
      window.dispatchEvent(event);
    }
  };
}
